<template>
  <router-view />
</template>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
